<template>
    <div>

        <media-drop-upload ref="mediaUpload"
            :get-uploaded="getUploaded"
            :selected-albums="selectedAlbums"
        ></media-drop-upload>

        <div class="row align-items-stretch">
            <div class="col-12">

                <div v-if="userAlbums" class="form-group">
                    <label for="media-albums">Attach Media to Albums</label>
                    <select v-model="selectedAlbums" multiple class="form-control" id="media-albums" name="albums[]">
                        <option v-for="album in userAlbums" :value="album.slug">
                            {{ album.name }}
                        </option>
                    </select>
                </div>

                <div class="form-group">
                    <label for="media-file">Media Files</label>
                    <div>
                        <input @change="selectFiles" type="file" id="media-file" name="file" multiple required>
                    </div>
                </div>

                <div class="form-group">
                    <button @click="formSubmitFiles" :disabled="!selectedFiles" class="btn btn-success">
                        Upload Media
                    </button>
                </div>

                <div class="card">
                    <div class="card-header">Uploaded Media</div>
                    <div class="row m-1">
                        <div v-for="(media, index) in medias" :key="index" class="col text-center">
                            <div>
                                <a :href="media.hyperlink">
                                    {{ media.slug }}
                                </a>
                            </div>
                            <div>
                                <small class="text-muted">
                                    <time :title="media.created_at_datetime">
                                        <span class="fa fa-calendar" aria-hidden="true"></span>
                                        {{ media.created_at_diffforhumans }}
                                    </time>
                                </small>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "MediaMultiUpload",
    mounted() {
        this.getAlbums();
        this.getUploaded();
    },
    data() {
        return {
            userAlbums: {},
            selectedAlbums: [],
            selectedFiles: undefined,
            medias: {},
        };
    },
    methods: {
        selectFiles() {
            this.selectedFiles = event.target.files;
        },
        formSubmitFiles() {
            this.$refs.mediaUpload.formSubmitFiles(this.selectedFiles);
        },
        getUploaded() {
            axios
                .get("/api/media/uploaded")
                .then(response => {
                    this.medias = response.data.data;
                });
        },
        getAlbums() {
            axios
                .get("/api/albums")
                .then(response => {
                    this.userAlbums = response.data.data;
                });
        },
    },
}
</script>

<style scoped>

</style>

<template>
    <div>

        <media-drop-upload
            :get-uploaded="getMedias"
        ></media-drop-upload>

        <transition-group v-if="medias.length" name="media-cards" tag="div" class="row align-items-stretch" appear>
            <div v-for="media in medias" :key="media.routekey" class="col-md-6 col-lg-4 px-1">
                <div class="card mb-4 mb-sm-2 shadow-sm">
                    <div v-if="media.displaytype === 'image'" class="card-body shadow-sm p-0">
                        <img :src="media.assetpath" :alt="media.name" class="card-img-contain" height="100%" width="100%" style="min-height:200px; max-height: 250px;">
                    </div>
                    <div class="card-footer px-3 py-1">
                        <div class="row mb-1">
                            <div class="col-12 text-center">
                                <span :class="[ media.name !== 'Unnamed' ? '' : 'text-muted font-italic']">
                                    {{ media.name }}
                                </span>
                            </div>
                            <div v-if="!media.is_owner" class="col">
                                <a :href="media.user.hyperlink">
                                    <span class="fa fa-user-circle" aria-hidden="true"></span>
                                    {{ media.user.username }}
                                </a>
                            </div>
                            <div class="col-auto ml-auto">
                                <small class="text-muted">
                                    <time :title="media.created_at_datetime">
                                        <span class="fa fa-calendar" aria-hidden="true"></span>
                                        {{ media.created_at_diffforhumans }}
                                    </time>
                                </small>
                            </div>
                        </div>
                        <div class="row mb-1">
                            <div class="col d-flex justify-content-between">
                                <div>
                                    <button v-if="media.albums_count" v-on:click="showAlbumList(media)" class="btn btn-sm btn-outline-info no-wrap">
                                        Albums
                                        <span class="fa-stack fa-stack-small">
                                            <span class="fa fa-square fa-stack-1x" aria-hidden="true"></span>
                                            <span class="fa fa-caret-down fa-stack-1x text-white-50" aria-hidden="true"></span>
                                        </span>
                                    </button>
                                </div>
                                <delete-media-buttons v-if="media.is_owner"
                                    :route-key="media.routekey"
                                    :on-success="removeMediaCard"
                                    :on-failure="removeFailure"
                                ></delete-media-buttons>
                                <div v-else class="btn-group">
                                    <a :href="media.hyperlink" class="btn btn-sm btn-outline-dark">
                                        View
                                    </a>
                                </div>
                            </div>
                        </div>
                        <transition duration="800" name="albums">
                            <div v-show="media.showAlbums" class="row h-auto border albums-outer">
                                <div class="col">
                                    <ul class="list-group list-group-flush albums-inner">
                                        <li v-for="album in media.albums" class="list-group-item bg-transparent p-1">
                                            <span class="float-right text-black-50">
                                                <span v-if="album.classification === 'private'" class="fa fa-lg fa-eye-slash" aria-hidden="true"></span>
                                                <span v-else-if="album.classification === 'hidden'" class="fa fa-lg fa-low-vision" aria-hidden="true"></span>
                                                <span v-else class="fa fa-lg fa-eye" aria-hidden="true"></span>
                                            </span>
                                            <a :href="album.hyperlink" class="text-decoration-none" :title="album.name">
                                                {{ album.name }}
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </transition>
                    </div>
                </div>
            </div>
        </transition-group>

        <div v-else-if="meta.current_page" class="row align-items-stretch">
            <transition name="media-cards" appear>
                <div class="col mx-auto">
                    <div class="card mb-4 mb-sm-2">
                        <div class="card-header"></div>
                        <div class="card-body">
                            <div class="card-text text-center">
                                No Media Found.
                            </div>
                        </div>
                        <div class="card-footer"></div>
                    </div>
                </div>
            </transition>
        </div>

        <pagination-links
            :navigation-links="links"
            :navigate="navigate"
        ></pagination-links>

    </div>
</template>

<script>
export default {
    name: "MediaCardPractical",
    mounted() {
        this.getMedias();
    },
    computed: {
        queryString: function () {
            return !!this.$route.query.view ? '?view=' + this.$route.query.view : '';
        },
        routeName: function () {
            return '/api' + this.$route.path;
        },
    },
    data() {
        return {
            medias: {
                displaytype: '',
                assetpath: '',
                created_at_diffforhumans: '',
                created_at_datetime: '',
                albums_count: 0,
                is_owner: false,
                classification: '',
                hyperlink: '',
                routekey: '',
                showAlbums: false,
                retrievedAlbums: false,
                albums: {},
            },
            links: {},
            meta: {
                current_page: 0,
            },
        };
    },
    methods: {
        getAlbums(media) {
            axios
                .get("/api/media/" + media.routekey + "/albums")
                .then(response => {
                    this.$set(media, 'albums', response.data.data);
                    this.$set(media, 'showAlbums', true);
                });
        },
        getMedias(address) {
            axios
                .get(address ? address : this.routeName + this.queryString)
                .then(response => {
                    this.medias = response.data.data;
                    this.links = response.data.links;
                    this.meta = response.data.meta;
                });
        },
        navigate(address) {
            this.getMedias(address)
        },
        showAlbumList(media) {
            if (media.showAlbums) {
                this.$set(media, 'showAlbums', false);
            } else {
                if (!media.retrievedAlbums) {
                    this.getAlbums(media);
                    media.retrievedAlbums = true
                } else {
                    this.$set(media, 'showAlbums', true);
                }
            }
        },
        removeMediaCard(key) {
            this.$delete(this.medias, this.medias.findIndex(media => media.routekey === key));
            setTimeout(this.getMedias, 300);
        },
        removeFailure(key) {
            // TODO: Proper Error Handling.
            console.log('Media could not be removed.');
        }
    }
}
</script>

<style scoped>
    .albums-outer {
        transform-origin: top;
    }
    .albums-enter-active,
    .albums-leave-active {
        transition: all 500ms ease-in;
        max-height: 1000px;
        overflow: hidden;
    }
    /* delay leave of parent element */
    .albums-leave-active {
        transition-delay: 250ms;
    }
    .albums-enter,
    .albums-leave-to {
        transform: scaleY(0);
        max-height: 0;
    }
    /* we can also transition nested elements using nested selectors */
    .albums-enter-active .albums-inner,
    .albums-leave-active .albums-inner {
        transition: all 300ms ease-in-out;
    }
    /* delay enter of nested element */
    .albums-enter-active .albums-inner {
        transition-delay: 250ms;
    }
    .albums-enter .albums-inner,
    .albums-leave-to .albums-inner {
        transform: translateY(-10px);
        opacity: 0;
    }

    .media-cards-enter-active,
    .media-cards-leave-active {
        transition: all 300ms ease;
    }
    .media-cards-enter,
    .media-cards-leave-to {
        transform: scale(0);
    }
</style>

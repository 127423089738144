<template>
    <div class="container my-4">
        <div class="row align-items-end">
            <div class="col-md-8 ml-auto">
                <h1 class="text-center">Editor</h1>
            </div>
            <div class="col-md-2 mr-auto d-flex justify-content-center">

            </div>
        </div>
        <div class="row justify-content-center">
            <div class="col-md-2 mr-auto mt-3 mt-sm-0 px-md-0 px-lg-3 order-md-last">
                <ul class="nav nav-pills nav-fill flex-column">
                    <li v-if="isRead" class="nav-item mb-1">
                        <router-link :to="{ name: 'create' }" :class="['nav-link bg-robrand text-white font-weight-bold px-0']">
                            <span class="fa fa-share" aria-hidden="true"></span>
                            NEW POST
                        </router-link>
                    </li>
                    <li v-if="!isRead" class="nav-item mb-1">
                        <router-link :to="{ name: 'read' }" :class="['nav-link bg-robrand text-white font-weight-bold px-0']">
                            <span class="fa fa-undo" aria-hidden="true"></span>
                            GO BACK
                        </router-link>
                    </li>
                </ul>
            </div>
            <div class="col-md-10 mx-auto mt-1 mt-sm-0">
                <div class="lead">
                    {{ lead }}
                </div>
            </div>
            <div class="col-md-8 ml-auto mt-1 mt-sm-0">
                <router-view @updateLead="setLead($event)"></router-view>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "EditorDashboard",
    computed: {
        isRead: function () {
            return this.$route.name === 'read';
        },
    },
    data() {
        return {
            lead: 'none'
        }
    },
    methods: {
        setLead(lead) {
            this.lead = lead;
        }
    },
}
</script>

<style scoped>

</style>

/**
 * First we will load all of this project's JavaScript dependencies which
 * includes Vue and other libraries. It is a great starting point when
 * building robust, powerful web applications using Vue and Laravel.
 */

require('./bootstrap');

import Vue from 'vue'
import VueRouter from 'vue-router'
import EditorDashboard from './components/EditorDashboard';
import AdminDashboard from './components/AdminDashboard'
import Create from './components/editor/Create'
import Read from './components/editor/Read'
import Update from './components/editor/Update'
import Comments from './components/Comments'

window.Vue = require('vue');

/**
 * The following block of code may be used to automatically register your
 * Vue components. It will recursively scan this directory for the Vue
 * components and automatically register them with their "basename".
 *
 * Eg. ./components/ExampleComponent.vue -> <example-component></example-component>
 */

// const files = require.context('./', true, /\.vue$/i)
// files.keys().map(key => Vue.component(key.split('/').pop().split('.')[0], files(key).default))

Vue.component('album-sync-form', require('./components/AlbumSyncForm').default);                          // media.update.blade
Vue.component('media-card-primitive', require('./components/MediaCardPrimitive').default);                // album.single.blade | MediaDropUpload.vue
Vue.component('media-card-practical', require('./components/MediaCardPractical').default);                // media.all.blade | media.index.blade | MediaDropUpload.vue
Vue.component('remove-media-buttons', require('./components/snippets/RemoveMediaButtons').default);       // album.single.blade MediaCardPrimitive.vue
Vue.component('media-multi-upload', require('./components/MediaMultiUpload').default);                    // media.upload.blade | MediaDropUpload.vue
Vue.component('vue-loading', require('vue-loading-overlay'));                                             // MediaDropUpload.vue
Vue.component('pagination-links', require('./components/snippets/PaginationLinks').default);              // media.all.blade MediaCardPractical.vue | media.index.blade MediaCardPractical.vue | editor.Read.vue
Vue.component('delete-media-buttons', require('./components/snippets/DeleteMediaButtons').default);       // media.all.blade MediaCardPractical.vue | media.index.blade MediaCardPractical.vue
Vue.component('media-drop-upload', require('./components/MediaDropUpload').default);                      // media.all.blade MediaCardPractical.vue | media.index.blade MediaCardPractical.vue | album.single.blade MediaCardPrimitive.vue

/**
 * Next, we will create a fresh Vue application instance and attach it to
 * the page. Then, you may begin adding components to this application
 * or customize the JavaScript scaffolding to fit your unique needs.
 */

Vue.use(VueRouter);

const routerAdmin = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/admin/dashboard',
            name: 'read',
            component: Read,
            props: true
        },
        {
            path: '/admin/create',
            name: 'create',
            component: Create,
            props: true
        },
        {
            path: '/admin/update',
            name: 'update',
            component: Update,
            props: true
        },
        {
            path: '/admin/*',
            redirect: {name: 'read'},
        },
    ],
});

const admin = new Vue({
    el: '#admin',
    router: routerAdmin,
    components: { AdminDashboard },
});


const router = new VueRouter({
    mode: 'history',
    routes: [
        {
            path: '/editor',
            component: EditorDashboard,
            props: true,
            children: [
                {
                    path: 'create',
                    name: 'create',
                    component: Create,
                },
                {
                    path: 'update',
                    name: 'update',
                    component: Update,
                    props: true,
                },
                {
                    path: '',
                    name: 'read',
                    component: Read,
                    alias: ['read', 'dashboard'],
                },
                {
                    path: '*',
                    redirect: {name: 'read'},
                },
            ]
        },
    ],
});

const app = new Vue({
    el: '#app',
    router,
    components: { Comments },
});

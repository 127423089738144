<template>
    <div>
        <div id="posts" v-for="post in posts" :key="post.id" class="row bg-white border mb-2 mb-sm-1 py-2">
            <div class="col-auto border-right my-auto">
                <img v-if="post.image" :src="'/uploads/posts/' + post.image" height="50" alt="Blog Post Image" />
                <img v-if="!post.image" src="http://placekitten.com/125/50" alt="Image Placeholder" />
            </div>
            <div class="col align-self-center">
                <div class="row">
                    <div class="col-auto align-self-center border-right">
                        {{ post.id }}
                    </div>
                    <div class="col">
                        {{ post.title }}
                    </div>
                </div>
            </div>
            <div class="col-auto align-self-center">
                <router-link :to="{ name: 'update', params: { postId : post.id } }">
                    <button type="button" class="btn btn-sm btn-outline-dark">
                        Update
                    </button>
                </router-link>
                <button type="button" data-toggle="modal" :data-target="['#ModalCenter' + post.id]" class="btn btn-sm btn-danger">
                    Delete
                </button>
            </div>
        </div>

        <div v-for="post in posts" class="modal fade" :id="['ModalCenter' + post.id]" tabindex="-1" role="dialog" aria-labelledby="ModalCenterTitle" aria-hidden="true">
            <div class="modal-dialog modal-dialog-centered" role="document">
                <div class="modal-content bg-gradient-dark text-white">
                    <div class="modal-header border-dark">
                        <h5 class="modal-title" id="ModalCenterTitle">Permanently Delete Post</h5>
                        <button type="button" class="close" data-dismiss="modal" aria-label="Close">
                            <span class="fa fa-times-circle text-white" aria-hidden="true"></span>
                        </button>
                    </div>
                    <div class="modal-body">
                        Are you sure you want to Permanently Delete Post [{{ post.id }}]?
                        <br>
                        "{{ post.title }}"
                    </div>
                    <div class="modal-footer border-dark">
                        <button type="button" class="btn btn-secondary" data-dismiss="modal">Close</button>
                        <button type="button" @click="deletePost(post.id)" class="btn btn-danger">Delete Post</button>
                    </div>
                </div>
            </div>
        </div>

        <pagination-links
            :navigation-links="links"
            :navigate="navigate"
        ></pagination-links>
    </div>
</template>

<script>
    export default {
        mounted() {
            this.$emit('updateLead', 'Read Blog Posts.');
            this.getPosts();
        },
        data() {
            return {
                posts: {},
                links: {}
            };
        },
        methods: {
            getPosts(address) {
                axios.get(address ? address : "/api/posts").then(response => {
                    this.posts = response.data.data;
                    this.links = response.data.links;
                    $(this.$el).fadeTo(1000, 1);
                });
            },
            deletePost(id) {
                //console.log("DELETE " + id);
                axios.delete("/api/posts/" + id).then(response => this.getPosts());
                $('#ModalCenter'+id).modal('hide');
                $(this.$el).fadeTo(1000, 0.2);
                //$('#Post'+id).slideUp(1000);
            },
            navigate(address) {
                this.getPosts(address)
            }
        }
    };
</script>

<style scoped>

</style>

<template>
    <div v-if="postId">

        <div>
            <div id="preview">
                <img v-if="url" :src="url" alt="Image Preview" />
                <img v-if="!url" src="http://placekitten.com/375/150" alt="Image Placeholder" />
            </div>
        </div>

        <form class="was-validated">

            <div class="form-group my-1">
                <div :class="['custom-file', errors.image ? 'is-invalid' : '']">
                    <input @change="onFileChange" type="file" ref="image" class="custom-file-input" id="image">
                    <label class="custom-file-label" for="image">Select a New Image for Blog Post...</label>
                </div>
                <div v-if="errors.image" class="invalid-feedback">{{ errors.image[0] }}</div>
            </div>

            <div class="form-group my-1">
                <label style="display:none" for="title"></label>
                <input type="text" ref="title" class="form-control" id="title" placeholder="Loading Blog Subject..." required>
                <div v-if="errors.title" class="invalid-feedback">{{ errors.title[0] }}</div>
            </div>

            <div class="form-group my-1">
                <label style="display:none" for="body"></label>
                <textarea class="form-control" ref="body" id="body" placeholder="Loading Blog Post..." rows="8" required></textarea>
                <div v-if="errors.body" class="invalid-feedback">{{ errors.body[0] }}</div>
            </div>

            <div v-if="successful||error" :class="['form-group m-1 p-3', successful ? 'alert-success' : '', error ? 'alert-danger' : '']">
                <span v-if="successful" class="label label-success">
                    Update Published!
                </span>
                <span v-if="error" class="label label-danger">
                    Error Encountered!
                </span>
                <button type="button" @click="closeAlert()" class="close" aria-label="Close">
                    <span aria-hidden="true">&times;</span>
                </button>
            </div>

            <button type="submit" @click.prevent="update" class="btn btn-primary block my-1">
                Update Blog Post
            </button>

        </form>

    </div>
</template>

<script>
    export default {
        mounted() {
            if (typeof this.postId !== 'undefined') {
                this.$emit('updateLead', 'Update Blog Post (ID:' + this.postId + ').');
                this.getPost();
            } else {
                this.$router.push({ name: 'read' });
            }
        },
        props: {
            postId: {
                type: [String, Number],
                required: true
            }
        },
        data() {
            return {
                error: false,
                successful: false,
                errors: [],
                url: null
            };
        },
        methods: {
            update() {
                const formData = new FormData();
                if (this.$refs.image.files[0]) {
                    formData.append("image", this.$refs.image.files[0]);
                }
                formData.append("title", this.$refs.title.value);
                formData.append("body", this.$refs.body.value);
                formData.append('_method', 'PUT');

                axios
                    .post("/api/posts/" + this.postId, formData)
                    .then(response => {
                        // Updated
                        if (response.status === 200) {
                            this.successful = true;
                            this.error = false;
                            this.errors = [];
                        } else {
                            this.successful = false;
                            this.error = true;
                            this.errors = [];
                        }
                    })
                    .catch(error => {
                        if (!_.isEmpty(error.response)) {
                            // Validation Error
                            if (error.response.status === 422) {
                                this.successful = false;
                                this.error = true;
                                this.errors = error.response.data.errors;
                            } else {
                                this.successful = false;
                                this.error = true;
                                this.errors = [];
                            }
                        }
                    });
            },
            getPost() {
                axios.get("/api/posts/" + this.postId).then(response => {
                    this.$refs.title.value = response.data.data.title;
                    this.$refs.body.value = response.data.data.body;
                    if (response.data.data.image !== null)
                    this.url = "/uploads/posts/" + response.data.data.image;
                });
            },
            onFileChange(e) {
                const file = e.target.files[0];
                if (typeof(file) === "undefined" || file === null) {
                    this.url = "http://placekitten.com/150/150";
                } else {
                    this.url = URL.createObjectURL(file);
                }
            },
            closeAlert() {
                this.successful = false;
                this.error = false;
            }
        }
    };
</script>

<style scoped>
    #preview {
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px dotted black;
        min-height: 150px;
    }
    #preview img {
        max-width: 100%;
        max-height: 300px;
    }
</style>

<template>
    <div class="row justify-content-around my-2 mx-0 mx-lg-5 px-0 px-lg-5">
        <div class="col-auto">
            <button type="button" v-on:click="navigate(navigationLinks.first)" :disabled="!navigationLinks.prev" class="btn btn-outline-dark no-wrap">
                <span class="fa fa-lg fa-angle-double-left" aria-hidden="true"></span>
                First
            </button>
        </div>
        <div class="col-auto">
            <button type="button" v-on:click="navigate(navigationLinks.prev)" :disabled="!navigationLinks.prev" class="btn btn-outline-dark no-wrap">
                <span class="fa fa-lg fa-angle-left" aria-hidden="true"></span>
                Previous
            </button>
        </div>
        <div class="col-auto">
            <button type="button" v-on:click="navigate(navigationLinks.next)" :disabled="!navigationLinks.next" class="btn btn-outline-dark no-wrap">
                Next
                <span class="fa fa-lg fa-angle-right" aria-hidden="true"></span>
            </button>
        </div>
        <div class="col-auto">
            <button type="button" v-on:click="navigate(navigationLinks.last)" :disabled="!navigationLinks.next" class="btn btn-outline-dark no-wrap">
                Last
                <span class="fa fa-lg fa-angle-double-right" aria-hidden="true"></span>
            </button>
        </div>
    </div>
</template>

<script>
export default {
    name: "PaginationLinks",
    props: {
        navigationLinks: {
            type: Object,
            required: true,
        },
        navigate: {
            type: Function,
            required: true,
        }
    },
}
</script>

<style scoped>

</style>

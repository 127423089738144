<template>
    <div>
        <div v-if="!toggleRemove" class="btn-group">
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-danger">
                Remove
            </button>
        </div>
        <div v-if="toggleRemove" class="btn-group">
            <slot name="button"></slot>
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-dark">
                No
            </button>
            <span class="btn btn-sm btn-dark">
                Remove?
            </span>
        </div>
    </div>
</template>

<script>
export default {
    name: "RemoveMediaButtons",
    data() {
        return {
            toggleRemove: false,
        };
    },
    methods: {
        toggleControls() {
            this.toggleRemove = !this.toggleRemove;
        },
    },
}
</script>

<style scoped>

</style>

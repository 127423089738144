<template>
    <div>
        <div class="form-group">
            <label for="media-albums">Sync Media to Albums</label>
            <select multiple class="form-control" id="media-albums" v-model="selectedIds">
                <option v-if="userAlbums.length" v-for="album in userAlbums" v-bind:value="album.slug">
                    {{ album.name }}
                </option>
            </select>
        </div>
        <div v-if="successful||error" :class="['form-group m-1 p-3', successful ? 'alert-success' : '', error ? 'alert-danger' : '']">
            <span v-if="successful" class="label label-success">
                Album Successfully Synced to Media!
            </span>
            <span v-if="error" class="label label-danger">
                Error Encountered!
            </span>
            <button type="button" @click="closeAlert()" class="close" aria-label="Close">
                <span aria-hidden="true">&times;</span>
            </button>
        </div>
        <button type="button" v-if="userAlbums.length" @click="syncAlbums()" class="btn btn-primary block my-1">
            Sync Media to Albums
        </button>
    </div>
</template>

<script>
export default {
    name: "AlbumSyncForm",
    mounted() {
        this.getAlbums();
    },
    props: {
        routeKey: {
            type: String,
            required: true
        },
        isOwner: {
            type: Boolean,
            required: true
        },
        userAlbums: {
            type: Array,
            required: true
        },
    },
    data() {
        return {
            selectedIds: [],
            albums: {
                slug: '',
                name: ''
            },
            successful: false,
            error: false,
        };
    },
    methods: {
        getAlbums() {
            axios
                .get("/media/" + this.routeKey + "/albums")
                .then(response => {
                    this.albums = response.data;
                    this.selectedIds = [];
                    this.albums.forEach(function (album) {
                        this.push(album.slug);
                    }, this.selectedIds);
                });
        },
        syncAlbums() {
            axios
                .put("/media/" + this.routeKey + "/albums/sync", {'albums': this.selectedIds})
                .then(response => {
                    if (response.status === 204) {
                        this.getAlbums()
                        this.successful = true;
                        this.error = false;
                    }
                })
                .catch(error => {
                    this.successful = false;
                    this.error = true;
                });
        },
        closeAlert() {
            this.successful = false;
            this.error = false;
        }
    },
};
</script>

<style scoped>

</style>

<template>
    <div>

        <media-drop-upload
            :get-uploaded="getMedias"
            :selected-albums="selectedAlbums"
        ></media-drop-upload>

        <div class="row align-items-center">
            <div class="col-md-12 mx-auto">

                <div class="bg-white border rounded text-center p-4">
                    <div class="row" v-if="medias.length">
                        <div class="col-lg-3 col-md-4 col-sm-6" v-for="media in medias" :key="media.slug">
                            <div class="card mb-4 mb-sm-2 shadow-sm">
                                <div class="card-body shadow-sm p-0 " style="height: 250px;">
                                    <img :src="('/./' + media.storagepath + media.filename)" :alt="media.name" class="card-img card-img-cover" height="100%" width="100%">
                                </div>
                                <div class="card-footer py-2" v-if="isOwner">
                                    <div class="row mb-1">
                                        <div class="col d-flex justify-content-between">
                                            <div></div>
                                            <remove-media-buttons>
                                                <template #button>
                                                    <button type="button" @click="detachMedia(media.slug)" class="btn btn-sm btn-outline-danger">
                                                        Yes
                                                    </button>
                                                </template>
                                            </remove-media-buttons>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "MediaCardPrimitive",
    mounted() {
        this.getMedias();
    },
    computed: {
        selectedAlbums: function () {
            return [this.routeKey];
        }
    },
    props: {
        routeKey: {
            type: String,
            required: true
        },
        isOwner: {
            type: Boolean,
            required: true
        }
    },
    data() {
        return {
            medias: {
                slug: '',
                storagepath: '',
                name: '',
                filename: ''
            },
        };
    },
    methods: {
        getMedias() {
            axios
                .get("/albums/" + this.routeKey + "/media")
                .then(response => {
                    this.medias = response.data;
                });
        },
        detachMedia(mediaSlug) {
            axios
                .put("/albums/" + this.routeKey + "/media/detach", {'medias': [mediaSlug]})
                .then(response => {
                    if (response.status === 204) {
                        this.getMedias();
                    }
                });
        },
    },
};
</script>

<style scoped>

</style>

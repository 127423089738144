<template>
    <div>
        <transition name="fade" mode="out-in">
        <div v-if="!toggleDelete" key="default" class="btn-group">
            <a :href="mediaShow" class="btn btn-sm btn-outline-dark">
                Show
            </a>
            <a :href="mediaEdit" class="btn btn-sm btn-outline-dark">
                Edit
            </a>
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-danger">
                Delete
            </button>
        </div>
        <div v-else key="confirm" class="btn-group">
            <button type="button" @click="deleteMedia()" class="btn btn-sm btn-outline-danger">
                Yes
            </button>
            <button type="button" @click="toggleControls()" class="btn btn-sm btn-outline-dark">
                No
            </button>
            <span class="btn btn-sm btn-dark">
                Delete?
            </span>
        </div>
        </transition>
    </div>
</template>

<script>
export default {
    name: "DeleteMediaButtons",
    data() {
        return {
            toggleDelete: false,
        };
    },
    computed: {
        mediaShow: function () {
            return window.location.origin + '/media/' + this.routeKey + '/show';
        },
        mediaEdit: function () {
            return window.location.origin + '/media/' + this.routeKey + '/edit';
        },
        mediaDelete: function () {
            return '/api/media/' + this.routeKey;
        }
    },
    props: {
        routeKey: {
            type: String,
            required: true
        },
        onSuccess: {
            type: Function,
            require: true
        },
        onFailure: {
            type: Function,
            require: true
        }
    },
    methods: {
        deleteMedia() {
            axios
                .delete(this.mediaDelete)
                .then(response => {
                    if (response.status === 204) {
                        this.onSuccess(this.routeKey);
                    } else {
                        this.onFailure(this.routeKey);
                    }
                })
        },
        toggleControls() {
            this.toggleDelete = !this.toggleDelete;
        },
    },
}
</script>

<style scoped>
    .fade-enter-active, .fade-leave-active {
        transition: all 250ms ease-out;
    }
    .fade-enter, .fade-leave-to {
        opacity: 0;
    }
    .fade-leave-to {
        transform: translateX(-10px);
    }
</style>

<template>
    <div>

        <vue-loading
            :active.sync="isDragging"
            :background-color="'#000000'"
        >
            <template v-slot:default>
                <span class="display-3 font-weight-bold text-white">
                    Drop files anywhere to upload.
                </span>
            </template>
        </vue-loading>

        <div class="row align-items-stretch">
            <div class="col-12">

                <transition name="progress-bars" appear>
                    <div v-if="progressInfos.length" class="alert alert-info">

                        <transition-group name="progress-bars" tag="div" appear>
                            <div v-for="(progressInfo, index) in progressInfos" v-bind:key="progressInfo.id" class="mb-2">
                                <div>
                                    <div>
                                        <span>{{ progressInfo.fileName }}</span>
                                        <button v-on:click="closeProgressBar(index)" type="button" aria-label="Close" class="close">
                                            <span aria-hidden="true">×</span>
                                        </button>
                                    </div>
                                    <div class="progress bg-white">
                                        <div role="progressbar"
                                             :aria-valuenow="progressInfo.percentage"
                                             aria-valuemin="0"
                                             aria-valuemax="100"
                                             class="progress-bar progress-bar-info"
                                             :style="{ width: progressInfo.percentage + '%' }"
                                        >
                                            {{ progressInfo.percentage + '%' }}
                                        </div>
                                    </div>
                                    <div>
                                        <span>{{ progressInfo.message }}</span>
                                    </div>
                                </div>
                            </div>
                        </transition-group>

                    </div>
                </transition>

            </div>
        </div>

    </div>
</template>

<script>
export default {
    name: "MediaDropUpload",
    mounted() {
        window.ondragleave = (event) => {
            event.preventDefault();
            this.isDragging = false;
        };
        window.ondragover = (event) => {
            event.preventDefault();
            if (event.dataTransfer.types.indexOf('Files') !== -1) {
                this.isDragging = true;
            }
        };
        window.ondrop = (event) => {
            event.preventDefault();
            this.isDragging = false;
            this.droppedFiles();
        };
    },
    props: {
        getUploaded: {
            type: Function,
            required: true
        },
        selectedAlbums: {
            type: Array,
            default: function () {
                return [];
            },
            required: false
        }
    },
    data() {
        return {
            selectedFiles: {},
            progressInfos: [],
            isDragging: false,
        };
    },
    methods: {
        droppedFiles() {
            this.selectedFiles = event.dataTransfer.files;
            this.uploadFiles();
        },
        formSubmitFiles(selectedFiles) {
            this.selectedFiles = selectedFiles;
            this.uploadFiles();
        },
        uploadFiles() {
            this.progressInfos = [];
            for (let i = 0; i < this.selectedFiles.length; i++) {
                this.upload(i, this.selectedFiles[i]);
            }
        },
        upload(index, file) {
            this.progressInfos[index] = { id: index, percentage: 0, fileName: file.name, message: 'Media uploading...', show: true };
            this.uploadService(file, this.selectedAlbums, (event) => {
                this.$set(this.progressInfos, index, Object.assign({}, this.progressInfos[index], {
                    percentage: Math.round(100 * event.loaded / event.total)
                }));
            })
                .then((response) => {
                    this.$set(this.progressInfos, index, Object.assign({}, this.progressInfos[index], {
                        message: response.data.message
                    }));
                    this.getUploaded();
                })
                .catch(() => {
                    this.$set(this.progressInfos, index, Object.assign({}, this.progressInfos[index], {
                        percentage: 0,
                        message: 'Could not upload file: ' + file.name
                    }));
                });
        },
        uploadService(file, albums, onUploadProgress) {
            let formData = new FormData();
            formData.append('file', file);
            albums.forEach((albums, index) => {
                formData.append('albums[' + index + ']', albums)
            }, formData);
            return axios.post('/api/media', formData, {
                headers: {
                    'Content-Type': 'multipart/form-data'
                },
                onUploadProgress
            });
        },
        closeProgressBar(index) {
            //this.$set(progressInfo, 'show', false);
            this.progressInfos.splice(index,1);
        },
    },
}
</script>

<style scoped>
    .progress-bars-enter-active,
    .progress-bars-leave-active {
        transition: opacity 500ms;
    }
    .progress-bars-enter,
    .progress-bars-leave-to {
        opacity: 0;
    }
</style>
